import React, { useEffect, useState } from 'react';
import '../styles/WishesAndVideo.css';
import videoFile from '../assets/video.mp4';

const wishes = [
  "Αγαπημενη μου Αλινα,",
  "",
  "Σου ευχομαι χρονια πολλα τζαι να τα",
  "εκατοστησειιισσς. Συνεχισε να καμνεις τους",
  "αλλους να γελουν οσο κακες τζαι να ειναι οι ",
  "μερες σου, να εισαι η καλυττερη listener σε ",
  "ολοκληρο το κοσμο τζαι να δουλευκεις",
  "σκληρα για τον εαυτο σουυυ. Συνεχισε να ",
  "εισαι η Αλινα τζαι ειμαι σιουρος οτι εννα",
  "πετυχεις οτι βαλεις στοχο στο μελλον.",
  "Χαρουμενα 22 αντ αι λαβ γιουυυυ, εισαι η",
  "πιο ομορφη γυναικα ολοκληρου τουυ κοσμουυυυ!!!",
  "",
  "Ματθαιοςςς"
];

function WishesAndVideo() {
  const [currentWish, setCurrentWish] = useState("");
  const [fullText, setFullText] = useState("");
  const [wishIndex, setWishIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const typingSpeed = 75; 

  useEffect(() => {
    if (wishIndex < wishes.length) {
      if (charIndex < wishes[wishIndex].length) {
        setTimeout(() => {
          setCurrentWish(currentWish + wishes[wishIndex][charIndex]);
          setCharIndex(charIndex + 1);
        }, typingSpeed);
      } else {
        setFullText(fullText + currentWish + "\n");
        setCurrentWish("");
        setCharIndex(0);
        setWishIndex(wishIndex + 1);
      }
    }
  }, [fullText, charIndex, wishIndex, currentWish]);

  return (
    <div className="split-container">
      <div className="left">
        <pre className="typing-effect">{fullText}{currentWish}</pre>
      </div>
      <div className="right">
        <video autoPlay loop muted playsInline>
          <source src={videoFile} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default WishesAndVideo;
