import React, { useState, useEffect, useRef } from 'react';
import '../styles/Gallery.css'; 

import img1 from '../assets/1.jpg';
import img2 from '../assets/2.jpg';
import img3 from '../assets/3.jpg';
import img4 from '../assets/4.jpg';
import img5 from '../assets/5.jpg';
import img6 from '../assets/6.jpg';
import img7 from '../assets/7.jpg';
import img8 from '../assets/8.jpg';
import img9 from '../assets/9.jpg';
import img10 from '../assets/10.jpg';
import img11 from '../assets/11.jpg';
import img12 from '../assets/12.jpg';
import img13 from '../assets/13.jpg';
import img14 from '../assets/14.jpg';
import img15 from '../assets/15.jpg';


const Gallery = () => {
  const images = [
    {
      src: img1,
      alt: 'Memory 1',
      caption: 'Memory 1 Caption'
    },
    {
      src: img2,
      alt: 'Memory 2',
      caption: 'Memory 2 Caption'
    },
    {
      src: img3,
      alt: 'Memory 3',
      caption: 'Memory 3 Caption'
    },
    {
      src: img4,
      alt: 'Memory 4',
      caption: 'Memory 4 Caption'
    },
    {
      src: img5,
      alt: 'Memory 5',
      caption: 'Memory 5 Caption'
    },
    {
      src: img6,
      alt: 'Memory 6',
      caption: 'Memory 6 Caption'
    },
    {
      src: img7,
      alt: 'Memory 7',
      caption: 'Memory 7 Caption'
    },
    {
      src: img8,
      alt: 'Memory 8',
      caption: 'Memory 8 Caption'
    },
    {
      src: img9,
      alt: 'Memory 9',
      caption: 'Memory 9 Caption'
    },
    {
      src: img10,
      alt: 'Memory 10',
      caption: 'Memory 10 Caption'
    },
    {
      src: img11,
      alt: 'Memory 11',
      caption: 'Memory 11 Caption'
    },
    {
      src: img12,
      alt: 'Memory 12',
      caption: 'Memory 12 Caption'
    },
    {
      src: img13,
      alt: 'Memory 13',
      caption: 'Memory 13 Caption'
    },
    {
      src: img14,
      alt: 'Memory 14',
      caption: 'Memory 14 Caption'
    },
    {
      src: img15,
      alt: 'Memory 15',
      caption: 'Memory 15 Caption'
    }
  ];

  const [currentItem, setCurrentItem] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      document.querySelectorAll('.gallery__image__caption').forEach((caption) => {
        caption.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
      });
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const showModal = (index) => {
    setCurrentItem(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const prevItem = () => {
    setCurrentItem((currentItem - 1 + images.length) % images.length);
  };

  const nextItem = () => {
    setCurrentItem((currentItem + 1) % images.length);
  };

  useEffect(() => {
    const handleKeyUp = (ev) => {
      if (ev.key === 'Escape' && isModalOpen) {
        closeModal();
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isModalOpen]);

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <div key={index} className="gallery__item" onClick={() => showModal(index)}>
          <img src={image.src} alt={image.alt} className="gallery__image" />
        </div>
      ))}
      {isModalOpen && (
        <div className="gallery__modal" ref={modalRef} aria-hidden={!isModalOpen}>
          <img src={images[currentItem].src} alt={images[currentItem].alt} />
          <button className="gallery__navigation--prev" onClick={prevItem}></button>
          <button className="gallery__navigation--next" onClick={nextItem}></button>
          <button className="gallery__navigation--close" onClick={closeModal}></button>
        </div>
      )}
    </div>
  );
};

export default Gallery;
