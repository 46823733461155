import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Alina.css'; 
import subjectImage from '../assets/Subject.png';  

function Alina() {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Hello Alina!",
    "Matthaios has been working on this since December.",
    "He told me so much about my future self, YOU.",
    "I'm so proud of what we've become.",
    "I heard that you managed to get into the Architecture study we always dreamed of. I am so proud.",
    "You also travelled to our favorite city, Florence!",
    "And many other countries, including Lisbon where you lived for a while.",
    "OMG, how was it?",
    "But amongst everything you stayed kind and kept smiling along the way.",
    "I hope you love yourself as much as I love you.",
    "You overcome every expectation we had, you are amazing!",
    "Happy birthday and I love you! Keep smiling! 🫰",
    "Click me for a surprise"
  ];

  const nextMessage = () => {
    if (messageIndex < messages.length - 1) {
      setMessageIndex(messageIndex + 1);
    }
  };

 
  const prevMessage = () => {
    if (messageIndex > 0) {
      setMessageIndex(messageIndex - 1);
    }
  };

  return (
    <div className="alina-container">
      <div className="image-container">
        <Link to="/birthday-card">
          <img src={subjectImage} alt="Younger Self" className="fixed-image" />
        </Link>
        <div className="speech-bubble">
          <p className="speech-text">{messages[messageIndex]}</p>
          <div className="fixed-navigation">
            <button onClick={prevMessage} className="prev-button">◄</button>
            <span className="message-counter">{messageIndex + 1}/{messages.length}</span>
            <button onClick={nextMessage} className="next-button">►</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alina;
