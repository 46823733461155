import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/BirthdayCard.css'; 
import Gallery from './Gallery'; 

function BirthdayCard() {
  return (
    <div className="birthday-card-container">
      <Link to="/wishes-and-video" className="proceed-button">
        Next page   ---------------------
      </Link>
      <h1>✨naturalmente felice✨</h1>
      <p>
        Το πιο όμορφο χαμόγελο ολόκληρου του κόσμουυυυυυυυ!!!! no 🧢
      </p>
      <Gallery />
    </div>
  );
}

export default BirthdayCard;
