import React, { useState } from 'react';
import '../styles/LoginForm.css'; // Ensure the CSS file path is correct

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'alina' && password === 'eisaimouni') {
      onLoginSuccess();
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <h1 className="hello-heading">
        Γειά σου,<span className="alina-gradient"> Alinaκιιιι</span>
      </h1>
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="login-input"
            placeholder=" "
          />
          <label htmlFor="username" className="login-input-label">
            Username
          </label>
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
            placeholder=" "
          />
          <label htmlFor="password" className="login-input-label">
            Password
          </label>
        </div>
        <button type="submit" className="login-button">
          →
        </button>
      </form>
      <div className="instructions">
        <div className="instruction-box">
          <h2>1.</h2>
          <p>βαλε το γιουζερνειμ τζαι τον κωδικοοο</p>
        </div>
        <div className="instruction-box">
          <h2>2.</h2>
          <p>εισαι η πρωτη επισκεπτης της ιστοσελιδας, παρε τον χρονο σου. Μολις μπείς στην ιστοσελιδα πανω δεξια εσηι επιλογη να βαλεις μουσικη(ρεκομεντετ).</p>
        </div>
        <div className="instruction-box">
          <h2>3.</h2>
          <p>ελπιζω να σου αρεσει!!!</p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
