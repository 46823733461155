import React from 'react';
import '../styles/VideoCredits.css';
import videoFile from '../assets/video.mp4';

function VideoCredits() {
  return (
    <div className="video-credits-container">
      <div className="video-wrapper">
        <video autoPlay loop muted playsInline className="rounded-video">
          <source src={videoFile} type="video/mp4" />
        </video>
      </div>
      <div className="text-container">
        <h2>Website Credits</h2>
        <p>
          This website was built starting from December. It took around 120 hours of work to design, develop, and perfect every detail.
        </p>
      </div>
    </div>
  );
}

export default VideoCredits;
