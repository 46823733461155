import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm'; 
import Alina from './components/Alina';
import BirthdayCard from './components/BirthdayCard';
import WishesAndVideo from './components/WishesAndVideo';
import VideoCredits from './components/VideoCredits';
import BackgroundMusic from './components/BackgroundMusic';

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  return (
    <Router>
      {isLoggedIn && <BackgroundMusic />}
      <Routes>
        <Route path="/" element={
          isLoggedIn ? <Navigate to="/alina" replace={true} /> : <LoginForm onLoginSuccess={() => setLoggedIn(true)} />
        } />
        <Route path="/alina" element={<Alina />} />
        <Route path="/birthday-card" element={<BirthdayCard />} />
        <Route path="/wishes-and-video" element={<WishesAndVideo />} />
        <Route path="/video-credits" element={<VideoCredits />} />
      </Routes>
    </Router>
  );
}

export default App;
