// src/components/BackgroundMusic.js
import React, { useState, useEffect, useRef } from 'react';
import backgroundMusic from '../assets/music.mp3'; 

const BackgroundMusic = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.5; 
      audioRef.current.loop = true;
    }
  }, []);

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch(error => {
        console.log('Audio play was prevented:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <button onClick={toggleAudio} style={buttonStyle}>
        {isPlaying ? 'Pause Music' : 'Play Music'}
      </button>
      <audio ref={audioRef} src={backgroundMusic} />
    </div>
  );
};

const buttonStyle = {
  position: 'fixed',
  top: '10px',
  right: '10px',
  padding: '10px 20px',
  fontSize: '16px',
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  zIndex: 1000,
};

export default BackgroundMusic;
